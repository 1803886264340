import { linkedin, github, twitter } from '../../assets/icons/svgIconsMap';

const footerData = {
  title: 'Find me on these platforms',
  copyRights: 'Made by Amjad Oudeh',
  socialLinks: [
    { name: 'LinkedIn', url: 'https://www.linkedin.com/in/amjad-oudeh-541418167/', icon: linkedin },
    { name: 'GitHub', url: 'https://www.github.com/Amjadoudeh', icon: github },
    { name: 'Twitter', url: 'https://twitter.com/OudehAmjad', icon: twitter },
  ],
};
export default footerData;
