import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { fullWave } from '../../assets/icons/svgIconsMap';
import footerData from './data';

const useStyles = makeStyles((theme) => ({
  appFooter: {
    position: 'relative',
    minHeight: 120,
    left: 0,
    width: '100%',
    margin: 0,
  },
  contentWrapper: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    marginTop: -1,
    background: theme.palette.primary.light,
  },
  backgroundContainer: {
    maxWidth: 'auto',
    width: '100vw',
    bottom: 0,
    maxHeight: '200px',
    overflow: 'hidden',
    '& svg': {
      width: '107%',
      height: '99px',
      marginBottom: '-4px',
      fill: theme.palette.primary.light, // Assuming the correct path is palette
      [theme.breakpoints.up('sm')]: {
        height: 200,
        width: '100%',
      },
    },
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    '& svg': {
      marginRight: theme.spacing(1),
      width: 'auto',
      height: 22,
      fill: 'white',
      [theme.breakpoints.up('sm')]: {
        height: 28,
        marginRight: theme.spacing(2),
      },
    },
    '& a': {
      color: 'white',
      textDecoration: 'none',
      fontSize: '0.8rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
    },
  },
  copyRight: {
    color: 'white',
    fontWeight: 'bold',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
  },
}));

// Correctly defined outside of AppFooter to avoid recursive calls or redefinition
function IconComponent({ icon }) {
  return !!icon && typeof icon === 'function' ? icon() : null;
}

export default function AppFooter({ content = footerData }) {
  const classes = useStyles();
  const { socialLinks, copyRights } = content;

  return (
    <div className={classes.appFooter} data-testid="appFooter-test">
      <div className={classes.backgroundContainer}>
        <IconComponent icon={fullWave} />
      </div>
      <Container className={classes.contentWrapper} maxWidth={false}>
        <Grid container>
          <Grid item md={3} xs={false} />
          {socialLinks.map((link) => (
            <Grid
              item
              key={link.name}
              md={6 / socialLinks.length}
              xs={12 / socialLinks.length}
              className={classes.linkItem}
            >
              <IconComponent icon={link.icon} />
              <a href={link.url} rel="noopener noreferrer" target="_blank">
                {link.name}
              </a>
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" className={classes.copyRight}>
          {copyRights}
        </Grid>
      </Container>
    </div>
  );
}
