import {
  frontend, testing, teamwork, devops, backend, tools,
} from '../../../assets/icons/svgIconsMap';
import html from '../../../assets/icons/png/html.png';
import js from '../../../assets/icons/png/javascript.png';
import react from '../../../assets/icons/png/react.png';
// import angular from '../../../assets/icons/png/angular.png';
import jquery from '../../../assets/icons/png/jquery.png';
// import redux from '../../../assets/icons/png/redux.png';

// import jest from '../../../assets/icons/png/jest.png';
// import testinglibrary from '../../../assets/icons/png/testinglibrary.png';
// import puppeteer from '../../../assets/icons/png/puppeteer.png';
// import protractor from '../../../assets/icons/png/protractor.png';
import swift from '../../../assets/icons/png/swift.png';
import swiftui from '../../../assets/icons/png/swiftui.png';
import SwiftKit from '../../../assets/icons/png/SwiftKit.png';

import sqlite from '../../../assets/icons/png/sqlite.png';
import sql from '../../../assets/icons/png/sql.png';
import firebase from '../../../assets/icons/png/firebase.png';
import python from '../../../assets/icons/png/Python.png';
// import mongodb from '../../../assets/icons/png/mongodb.png';
// import postgresql from '../../../assets/icons/png/postgresql.png';

import cicd from '../../../assets/icons/png/cicd.png';
import docker from '../../../assets/icons/png/docker.png';
import Gocd from '../../../assets/icons/png/Gocd.png';
// import aws from '../../../assets/icons/png/aws.png';

import git from '../../../assets/icons/png/git.png';
import vscode from '../../../assets/icons/png/vscode.png';
import debug from '../../../assets/icons/png/bug.png';
// import api from '../../../assets/icons/png/api.png';
import xcode from '../../../assets/icons/png/xcode.png';
import agile from '../../../assets/icons/png/agile.png';
import atlassian from '../../../assets/icons/png/atlassian.png';
// import sketch from '../../../assets/icons/png/sketch.png';
// import collaborating from '../../../assets/icons/png/collaborating.png';
import slack from '../../../assets/icons/png/slack.png';
import asana from '../../../assets/icons/png/asana.png';
// import corssFunction from '../../../assets/icons/png/crossfunction.png';

export const headerData = {
  title: 'Skills and TechStack',
  description: 'Solid experience in designing and delivering Apps, with a strong focus on Front-End',
};

const contentData = [
  {
    title: 'Front-End',
    icon: frontend,
    listItems: [
      {
        title: 'HTML-CSS',
        subTitle: 'Sass - Bootstrap ',
        icon: html,
      },
      {
        title: 'JavaScript',
        subTitle: 'ES6-ES7',
        icon: js,
      },
      {
        title: 'React',
        subTitle: 'hooks/best practices',
        icon: react,
      },
      // {
      //   title: 'Angular',
      //   subTitle: 'V2-5',
      //   icon: angular,
      // },
      {
        title: 'Legacy frameworks',
        subTitle: 'JQuery',
        icon: jquery,
      },
      // {
      //   title: 'Redux/ Saga',
      //   subTitle: '',
      //   icon: redux,
      // },
    ],
  },
  // {
  //   title: 'Testing',
  //   icon: testing,
  //   listItems: [
  //     // {
  //     //   title: 'Jest',
  //     //   subTitle: 'for React apps',
  //     //   icon: jest,
  //     // },
  //     // {
  //     //   title: 'Testing Library',
  //     //   subTitle: 'functional/integration',
  //     //   icon: testinglibrary,
  //     // },
  //     // {
  //     //   title: 'Protractor',
  //     //   subTitle: 'Angular/E2E test',
  //     //   icon: protractor,
  //     // },
  //     // {
  //     //   title: 'Puppeteer',
  //     //   subTitle: 'React/E2E test',
  //     //   icon: puppeteer,
  //     // },
  //   ],
  // },
  {
    title: 'IOS',
    icon: testing, // temporary
    listItems: [
      {
        title: 'Swift',
        subTitle: 'apple programming language',
        icon: swift,
      },
      {
        title: 'Swift-UI',
        subTitle: 'UI library',
        icon: swiftui,
      },
      {
        title: 'Swift-Kit',
        subTitle: 'Kit library',
        icon: SwiftKit,
      },
      // {
      //   title: 'Puppeteer',
      //   subTitle: 'React/E2E test',
      //   icon: puppeteer,
      // },
    ],
  },
  {
    title: 'BE/ Databases',
    icon: backend,
    listItems: [
      {
        title: 'SQL',
        subTitle: 'SQL DB',
        icon: sql,
      },
      {
        title: 'Sqlite3',
        subTitle: 'Sqlite DB',
        icon: sqlite,
      },
      {
        title: 'FireBase',
        subTitle: 'backend and hosting',
        icon: firebase,
      },
      // {
      //   title: 'PosgreSQL',
      //   subTitle: 'Relational DB',
      //   icon: postgresql,
      // },
      {
        title: 'Python',
        subTitle: 'Flask, Django',
        icon: python,
      },
      // {
      //   title: 'MongoDB',
      //   subTitle: 'Non SQL DB',
      //   icon: mongodb,
      // },

    ],
  },
  {
    title: 'DevOps/deploy',
    icon: devops,
    listItems: [
      {
        title: 'CI/CD',
        subTitle: ' Github actions',
        icon: cicd,
      },
      {
        title: 'Containers',
        subTitle: 'Docker',
        icon: docker,
      },
      {
        title: 'Go cd',
        subTitle: 'continuous delivery',
        icon: Gocd,
      },
      // {
      //   title: 'AWS Hosting',
      //   subTitle: 'S3, CloudFront, lambda',
      //   icon: aws,
      // },
    ],
  },
  {
    title: 'Developer tools',
    icon: tools,
    listItems: [
      {
        title: 'Git',
        subTitle: 'versioning control, Github. BitBucket',
        icon: git,
      },
      {
        title: 'Browser\'s developer tools',
        subTitle: 'Analysis and debug',
        icon: debug,
      },
      // {
      //   title: 'Network',
      //   subTitle: 'HTTPS, CORS, RESTful API',
      //   icon: api,
      // },
      {
        title: 'Modern Editors',
        subTitle: 'code pairing/ remote working',
        icon: vscode,
      },
      {
        title: 'Xcode',
        subTitle: 'IOS Editors',
        icon: xcode,
      },
    ],
  },
  {
    title: 'Team work',
    icon: teamwork,
    listItems: [
      {
        title: 'JIRA/ Confluence',
        subTitle: 'Project managing',
        icon: atlassian,
      },
      {
        title: 'Asana',
        subTitle: '',
        icon: asana,
      },
      {
        title: 'Agile',
        subTitle: 'Kanban, Scrums, Sprint',
        icon: agile,
      },
      // {
      //   title: 'Cross functional',
      //   subTitle: 'FE, BE, DevOps',
      //   icon: corssFunction,
      // },
      // {
      //   title: 'Collaborating tools',
      //   subTitle: 'for UI, Zipplem, insomia, ',
      //   icon: collaborating,
      // },
      {
        title: 'Slack',
        subTitle: ' ',
        icon: slack,
      },
      // {
      //   title: 'Design/ wire framing',
      //   subTitle: 'Adobe XD, Sketch, Figma',
      //   icon: sketch,
      // },
    ],
  },
];

export default contentData;
