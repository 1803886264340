export const headerData = {
  title: 'Experiences',
  description: 'list of my recent jobs and programing related experiences',
};

const timelineItems = [
  {
    time: 'Sep 2021 - Present',
    title: 'iOS engineer',
    description: 'Full time',
    company: {
      name: 'SoundCloud',
      link: 'https://SoundCloud.com/',
    },
    location: 'Berlin - Germany',
    workList: [
      'As the iOS QA Engineer and Release Manager at SoundCloud, I excel in delivering a top-notch iOS app experience to millions of users worldwide. My role includes:',
      'Ensuring rigorous quality assurance practices to guarantee an exceptional user experience.',
      'Orchestrating end-to-end release management, from planning to deployment.',
      'Collaborating with cross-functional teams, including designers, product managers, and Backend Engineers, in an agile development environment.',
      'Maintaining code quality through reviews and promoting a culture of continuous improvement.',
      'Writing comprehensive test cases to validate the functionality and reliability of the iOS app.',
      'Synchronizing efforts across teams to ensure a seamless and coordinated release process.',
      'Developing UI tests using Xcode to validate the user interface and overall user experience.',
      'Occasionally contributing to feature development work, leveraging my skills to enhance the app\'s functionality and user experience.',
      'I contribute to SoundCloud\'s mission of bringing music closer to people\'s hearts while ensuring a smooth and secure mobile app experience.'],
    references: false,
    jobDescription: 'See SoundCloud website',
  },
  {
    time: 'May 2020 - Aug 2021',
    title: 'Front-End engineer',
    description: 'Full time',
    company: {
      name: 'Freelance',
      link: 'https://redi-school.github.io/react-21s-final-project-1/',
    },
    location: 'Germany',
    workList: ['Developing websites, converting mock-ups into usable web presence with HTML, CSS, JavaScript, AJAX and JSON coding.',
      'Investigating and resolving application issues for web-based programs providing end-user support.',
      'Attending professional training to perfect competencies and professional skills.',
      'Project management, leadership and strategy planning.'],
    references: false,
    jobDescription: 'See some of my Projects',
  },
];
export default timelineItems;
